<template>
  <el-row class="footBox">
     <el-col :class="flag ? 'noblockBox' : 'blockBox '">
       <el-row class="describeBox">
         <el-col :span="6"  class="text-center">
          <div class="grid-content bg-purple">
            <div class="pull-left">
              <div class="iconfont">
                <img :src="renzheng" alt="renzheng">
              </div>
            </div>
            <div class="pull-left">
              <strong>药监认证</strong>
              <p>权威认证 值得信赖</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="text-center">
          <div class="grid-content bg-purple">
            <div class="pull-left">
              <div class="iconfont">
                <img :src="pinzhi" alt="pinzhi">
              </div>
            </div>
            <div class="pull-left">
              <strong>品质保障</strong>
              <p>品质护航 购物无忧</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="text-center">
          <div class="grid-content bg-purple">
            <div class="pull-left">
              <div class="iconfont">
                <img :src="wuliu" alt="wuliu">
              </div>
            </div>
            <div class="pull-left">
              <strong>极速物流</strong>
              <p>多仓直发 极速配送</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="text-center">
          <div class="grid-content bg-purple">
            <div class="pull-left">
              <div class="iconfont">
                <img :src="shouhou" alt="shouhou">
              </div>
            </div>
            <div class="pull-left">
              <strong>售后无忧</strong>
              <p>消费保障 退换无忧</p>
            </div>
          </div>
        </el-col>
       
      </el-row>
     
     </el-col>
    <el-col :class="flag ? 'footer  loginbg' : 'footer'">
      <el-row>
        <el-col :span="24" class="item">
          <div  v-for="(item, index) in arr" :key="index" @click="jumpPath(index)">
            {{ item }}
          </div>
        </el-col>
         <el-col :span="24" class="aq">{{ JGmsg }}</el-col>
        <el-col :span="24"  class="aq">
           {{ copyInfo }}
        </el-col>
         <el-col :span="24" class="aq hoverBox" >
          {{ Onlineaq }}
        </el-col>
        <el-col :span="24" class="aq hoverBox" @click.native="jumpIcpUrl()">
          <!-- {{ aq }} -->

          <span v-if="isComDomain">
            <!-- 这是.com域名 -->
            晋ICP备2021008814号-5
          </span>  
          <span v-else-if="isCnDomain">
            <!-- 这是.cn域名 -->
            {{ aq }}
          </span>  
          <span v-else>无法判断或不是.com或.cn域名</span>  


        </el-col>
      </el-row>
    </el-col>
      <el-col   :class="flag ? 'footer  loginbg' : 'footer'">
          <el-row  class="bottomJumpBox">
             <el-col :span="6" class="contacts text-center"  v-for="(item, index) in bottomIcon" :key="index" @click.native="jumpUrl(item.url)"><img :src="item.img" :alt="item.name"></el-col>
         </el-row>
      </el-col>
  </el-row>
</template>

<style lang="less" scoped>
@import "~style/index.less";

.footBox{
  background:#f6f6f6;
}
.noblockBox{
  display: none;
}
.blockBox{
  display: block;
}
  .describeBox{
    width: 1200px;
    margin:0 auto;
      background: #f6f6f6;
    .grid-content{
      padding:20px 0 10px;
      width: 172px;
      margin: 0 auto;
      height: 46px;
    }
    .pull-left{
      float: left;
          text-align: left;
      .strong{
        line-height: 30px;
        font-size: 16px;
      }
      p{
        line-height: 25px;
        color: #999999;
        font-size: 14px;
      }
    }
    .iconfont{
      margin-right: 10px;
      width: 44px;
      height:44px;
      img{
        width:100%;
        height: auto;
      }
    }
  }
  .contact{
    line-height: 30px;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
    font-size: 14px;
    .iconfont{
      margin-right:10px;
    color: #ff3333;
    font-size: 18px;
    }
  }

.footer {
  width: 100%;
   background: #f6f6f6;
  &.loginbg {
    background: #fff;
     color: #8c8b8b;
  }
  color: #8c8b8b;
  margin: 0 auto;
  .text-center();
  display: flex;
  justify-content: center;
  .item {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 15px;
     color: #000;
    font-size: 12px;
    div {
      margin-left: 20px;
      padding-right: 20px;
      border-right: 1px solid #333;
      line-height: 16px;
    }
    div:nth-child(1) {
       cursor: pointer;
       &:hover{
           color: #f33;
       }
    }
     div:nth-child(2) {
       cursor: pointer;
       &:hover{
           color: #f43c38;
       }
    }
     div:nth-child(3) {
       cursor: pointer;
       &:hover{
           color: #f33;
       }
    }
     div:nth-child(4) {
       cursor: pointer;
       &:hover{
           color: #f43c38;
       }
    }
    div:last-child {
      border-right: none;
    }
  }
 .aq {
      color: #000;
    font-size: 12px;
    padding-top:6px;
    padding-bottom:6px;
  
  }
  .hoverBox{
    cursor: pointer;
       &:hover{
        color: #f33;
      }
  }
   
}
  .bottomJumpBox{
    width: 500px;
    margin:0 auto 30px;
  
    img{
        width:auto;
        height:18px;
        cursor: pointer;
        &:hover{
          border: 1px solid #f33;
        }
      }
  }
</style>

<script>
import renzheng from "assets/renzheng.png";
import shouhou from "assets/shouhou.png";
import pinzhi from "assets/pinzhi.png";
import wuliu from "assets/wuliu.png";
import bottomIcon1 from "assets/bottomIcon1.png";
import bottomIcon2 from "assets/bottomIcon2.png";
import bottomIcon3 from "assets/bottomIcon3.png";
import bottomIcon4 from "assets/bottomIcon4.png";
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      renzheng:renzheng,
       shouhou:shouhou,
        pinzhi:pinzhi,
         wuliu:wuliu,
      bottomIcon:[
           {
           img:bottomIcon1,
            url:'https://www.12377.cn/node_548446.htm',
            name:"网络举报app下载"
          },
          {
            img:bottomIcon2,
            url:'https://www.12377.cn/',
            name:"网上有害信息举报专区"
          },
         {
            img:bottomIcon3,
            url:'http://www.cyberpolice.cn/wfjb/',
            name:"网络警察"
          },
          {
            img:bottomIcon4,
            url:'http://www.shdf.gov.cn/shdf/channels/740.html',
            name:"扫黄打非"
          },
      ],
     
      arr: [
        "关于我们",
        "联系我们",
        "隐私政策",
         "用户协议",
        "法律声明",
        "客户服务",
        "友情链接",
      ],
      JGmsg:"监管机构：国家食品药品监督管理局　山西省食品药品监督管理局　太原市食品药品监督管理局",
      copyInfo: "Copyright © 2018-2021 复盛公药业集团 版权所有",
      aq: "备案号:晋ICP备18000185号-3",
      Onlineaq:"互联网药品信息服务资格证书证书编号:(晋)-非经营性-2021-0058",
      isComDomain: false,  
      isCnDomain: false  
    };
  },
  watch: {

  },
   computed: {
    //计算属性可实时更新购物车数量
    ...mapState({
      flag: (state) => state.flag,
    }),
  },
  methods:{
    checkDomain() {  
        const url = window.location.href;  
        const parser = document.createElement('a');  
        parser.href = url;  
        const hostname = parser.hostname; // 获取主机名  
        const tldArray = hostname.split('.').reverse(); // 反转数组以便从右到左检查  
        let tld = '';  
        
        // 找出TLD，这通常涉及检查公共的TLD列表，但这里我们简化处理  
        // 假设TLD是最后两个部分的组合，这可能不适用于所有情况  
        if (tldArray.length >= 2) {  
          tld = tldArray.slice(0, 2).reverse().join('.');  
        } else {  
          tld = tldArray.join('.'); // 如果只有一个部分，则整个部分就是TLD  
        }  
        
        console.log(tld); // 打印TLD以便调试  
        
        // 检查TLD是否包含'.com'  
        this.isComDomain = tld.toLowerCase().includes('.com'); // 转为小写以确保匹配不区分大小写  
        this.isCnDomain = tld.toLowerCase().includes('.cn'); // 对于.cn，我们仍然需要精确匹配  
      },
    jumpPath(i){
      switch(i){
        case 0:
         this.$router.push({name:"AboutUs"})
         break;
        case 1:
         this.$router.push({name:"AboutUs"})
         break;
        case 2:
         this.$router.push({name:"Agreement"})
         break;
        case 3:
        // 用户协议
         this.$router.push({name:"UserAgreement"})
      }
    },
    jumpIcpUrl(){
      window.open("https://beian.miit.gov.cn/")
    },
    jumpUrl(url){
       window.open(url)
    }
    // reload(){
    //     if (sessionStorage.getItem("token") != null) {
    //       this.flag = true;
    //       this.$forceUpdate() //手动强制刷新。
    //     } else {
    //         this.flag = false;
    //       this.$forceUpdate()
    //     }
    // }
  },
  mounted() {  
    this.checkDomain();  
  },  
  created()  {
    // this.reload();
  },
};
</script>
 <!-- <el-row>
        <el-col :span="8" class="contact text-center">
           <i class="iconfont el-icon-phone-outline"></i>
              <strong>一区总监：李刚：18535529777</strong>
        </el-col>
        <el-col :span="8" class="contact text-center">
           <i class="iconfont el-icon-phone-outline"></i>
             <strong>二区总监：任敏：18303518269</strong>
        </el-col>
        <el-col :span="8" class="contact text-center">
           <i class="iconfont  el-icon-phone-outline"></i>
               <strong>监督热线:15315070738</strong>
        </el-col>
      </el-row> -->